import {
  ForethoughtLiveChatHandoffComponent,
  ZendeskLiveChatIntegrationConfig,
} from 'api/types';
import {
  ForethoughtEvent,
  ForethoughtSendTrackingEvent,
  ForethoughtZopimFailureEvent,
  ForethoughtZopimPendingEvent,
} from './embed';
import { debounce } from 'utils/debounce';
import { postMessage } from 'utils/postMessage';
import { FORETHOUGHT_IFRAME_ID } from './constants';
import { ZendeskJwtAuthFn } from './zendesk';

type ZopimErrorCallback = (err: Error) => void;

type ZopimErrorCallbackWithData = (
  err: Error,
  data: Record<string, unknown>,
) => void;

/**
 * https://api.zopim.com/web-sdk/#events
 */
type ZopimEvents =
  | 'account_status'
  | 'connection_update'
  | 'department_update'
  | 'visitor_update'
  | 'agent_update'
  | 'chat'
  | 'history'
  | 'error';

type ZopimAccountStatus = 'online' | 'away' | 'offline';

type ZopimDepartmentStatus = 'online' | 'away' | 'offline';

/**
 * https://api.zopim.com/web-sdk/#chat-2
 */
interface ZopimChatBaseEventData {
  nick: string;
  type:
    | 'chat.msg'
    | 'chat.file'
    | 'chat.queue_position'
    | 'chat.memberjoin'
    | 'chat.memberleave'
    | 'chat.request.rating'
    | 'chat.rating'
    | 'chat.comment'
    | 'typing'
    | 'last_read';
}

export interface ZopimAgentInfo {
  avatar_path: string;
  display_name: string;
  nick: string;
  title: string;
}

interface ZopimQuickReply {
  action: {
    type: 'QUICK_REPLY_ACTION';
    value: string;
  };
  text: string;
}

/**
 * https://api.zopim.com/web-sdk/structured-message.html#quick-replies
 */
interface ZopimQuickReplyStructuredMessage {
  msg: string;
  quick_replies: ZopimQuickReply[];
  type: 'QUICK_REPLIES';
}

interface ZopimButton {
  action: {
    type: 'LINK_ACTION' | 'QUICK_REPLY_ACTION';
    value: string;
  };
  text: string;
}

/**
 * https://api.zopim.com/web-sdk/structured-message.html#button-template
 */
interface ZopimButtonsStructuredMessage {
  buttons: ZopimButton[];
  msg: string;
  type: 'BUTTON_TEMPLATE';
}

/**
 * https://api.zopim.com/web-sdk/#chat-msg-chat-event-type
 */
export interface ZopimChatMsgEventData extends ZopimChatBaseEventData {
  display_name: string;
  msg: string;
  options: string[];
  structured_msg?:
    | ZopimQuickReplyStructuredMessage
    | ZopimButtonsStructuredMessage
    | never;
  timestamp: number;
  type: 'chat.msg';
}

interface ZopimMetadata {
  height: number;
  width: number;
}

interface ZopimAttachment {
  metadata?: ZopimMetadata;
  mime_type: string;
  name: string;
  size: number;
  url: string;
}

/**
 * https://api.zopim.com/web-sdk/#chat-file-chat-event-type
 */
export interface ZopimChatFileEventData extends ZopimChatBaseEventData {
  attachment: ZopimAttachment;
  deleted: boolean;
  display_name: string;
  timestamp: number;
  type: 'chat.file';
}

interface ZopimMemberEventData {
  display_name: string;
  timestamp: number;
}

/**
 * https://api.zopim.com/web-sdk/#chat-memberjoin-chat-event-type
 */
export interface ZopimMemberJoinEventData
  extends ZopimMemberEventData,
    ZopimChatBaseEventData {
  type: 'chat.memberjoin';
}

/**
 * https://api.zopim.com/web-sdk/#chat-memberleave-chat-event-type
 */
export interface ZopimMemberLeaveEventData
  extends ZopimMemberEventData,
    ZopimChatBaseEventData {
  type: 'chat.memberleave';
}

/**
 * https://api.zopim.com/web-sdk/#chat-queue_position-chat-event-type
 */
export interface ZopimQueuePositionEventData extends ZopimChatBaseEventData {
  nick: 'system:queue';
  queue_position: number;
  type: 'chat.queue_position';
}

type ZopimChatEventData =
  | ZopimChatMsgEventData
  | ZopimChatFileEventData
  | ZopimMemberJoinEventData
  | ZopimMemberLeaveEventData
  | ZopimQueuePositionEventData;

/**
 * https://api.zopim.com/web-sdk/#39-connection_update-39-event
 */
export type ZopimConnectionStatus = 'connecting' | 'connected' | 'closed';

export type ZopimEventData<T extends ZopimEvents> = T extends 'agent_update'
  ? ZopimAgentInfo
  : T extends 'chat'
    ? ZopimChatEventData
    : T extends 'connection_update'
      ? ZopimConnectionStatus
      : unknown;

interface ZopimDepartment {
  id: number;
  name: string;
  status: ZopimDepartmentStatus;
}

interface ZopimInitOptions {
  account_key: string;
  authentication?: { jwt_fn: ZendeskJwtAuthFn };
  suppress_console_error?: boolean;
}

/**
 * https://api.zopim.com/web-sdk/
 */
export interface ZopimSdk {
  /**
   * https://api.zopim.com/web-sdk/#zchat-addtags-tags-callback
   */
  addTags: (tags: string[], callback?: ZopimErrorCallback) => void;
  /**
   * https://api.zopim.com/web-sdk/#zchat-clearvisitordefaultdepartment-callback
   */
  clearVisitorDefaultDepartment: (callback?: ZopimErrorCallback) => void;
  /**
   * https://api.zopim.com/web-sdk/#zchat-endchat-options-callback
   */
  endChat: (
    options?: { clear_dept_id_on_chat_ended: boolean },
    callback?: ZopimErrorCallback,
  ) => void;
  /**
   * https://api.zopim.com/web-sdk/#zchat-getaccountstatus
   */
  getAccountStatus: () => ZopimAccountStatus;
  /**
   * https://api.zopim.com/web-sdk/#zchat-getdepartment-id
   */
  getDepartment: (id: number) => ZopimDepartment | undefined;
  /**
   * https://api.zopim.com/web-sdk/#zchat-getservingagentsinfo
   */
  getServingAgentsInfo: () => ZopimAgentInfo[];
  /**
   * https://api.zopim.com/web-sdk/#zchat-init-options
   */
  init: (options: ZopimInitOptions) => void;
  /**
   * https://api.zopim.com/web-sdk/#zchat-ischatting
   */
  isChatting: () => boolean;
  /**
   * https://api.zopim.com/web-sdk/#zchat-on-event_name-handler
   */
  on: <T extends ZopimEvents>(
    event: T,
    handler: (event_data: ZopimEventData<T>) => void,
  ) => void;
  /**
   * https://api.zopim.com/web-sdk/#zchat-removetags-tags-callback
   */
  removeTags: (tags: string[], callback?: ZopimErrorCallback) => void;
  /**
   * https://api.zopim.com/web-sdk/#zchat-sendchatcomment-comment-callback
   */
  sendChatComment: (comment: string, callback?: ZopimErrorCallback) => void;
  /**
   * https://api.zopim.com/web-sdk/#zchat-sendchatmsg-msg-callback
   */
  sendChatMsg: (message: string, callback?: ZopimErrorCallback) => void;
  /**
   * https://api.zopim.com/web-sdk/#zchat-sendchatrating-rating-callback
   */
  sendChatRating: (
    rating: 'good' | 'bad' | 'null',
    callback?: ZopimErrorCallback,
  ) => void;
  /**
   * https://api.zopim.com/web-sdk/#zchat-sendchatmsg-msg-callback
   *
   * I have added content because we need to send the file content from inside
   * the iframe to the parent page
   */
  sendFile: (
    file: File,
    content?: string | ArrayBuffer | null,
    callback?: ZopimErrorCallbackWithData,
  ) => void;
  /**
   * https://api.zopim.com/web-sdk/#zchat-setvisitordefaultdepartment-id-callback
   */
  setVisitorDefaultDepartment: (
    id: number,
    callback?: ZopimErrorCallback,
  ) => void;
  /**
   * https://api.zopim.com/web-sdk/#zchat-setvisitorinfo-options-callback
   */
  setVisitorInfo: (
    visitorInfo: {
      display_name?: string;
      email?: string;
      phone?: string;
    },
    callback?: ZopimErrorCallback,
  ) => void;
  /**
   * https://api.zopim.com/web-sdk/#zchat-un-event_name-handler
   */
  un: <T extends ZopimEvents>(
    event: T,
    handler: (event_data: ZopimEventData<T>) => void,
  ) => void;
}

type ZopimFunctionWithCallback =
  | 'addTags'
  | 'clearVisitorDefaultDepartment'
  | 'endChat'
  | 'removeTags'
  | 'sendChatComment'
  | 'sendChatMsg'
  | 'sendChatRating'
  | 'sendFile'
  | 'setVisitorDefaultDepartment'
  | 'setVisitorInfo';

type ZopimFunctionArgsHelper<K extends keyof ZopimSdk> = ZopimSdk[K] extends (
  ...args: infer P
) => unknown
  ? P
  : never;

export const ZOPIM_SCRIPT_ID = 'forethought-zopim';
export const ZENDESK_WEB_SDK =
  'https://dev.zopim.com/web-sdk/1.11.2/web-sdk.js';

/**
 * Call similar to applyZopimFunction but this will add
 * a callback to the method call and will return a promise
 */
const applyZopimFunctionWithCallback = <
  K extends keyof Pick<ZopimSdk, ZopimFunctionWithCallback>,
>(
  fn: K,
  ...args: ZopimFunctionArgsHelper<K>
) => {
  return new Promise<void>(resolve => {
    if (!window.zChat) {
      return resolve();
    }

    // @ts-expect-error TS2556
    window.zChat?.[fn](...args, err => {
      // don't log endChat errors because we attempt to
      // end the chat every time we start a new one
      if (err && fn !== 'endChat') {
        console.error({ error: err, fn });
      }
      resolve();
    });
  });
};

/**
 * Debounce due to multiple re-renders / message events
 */
export const debouncedSetupZopimSdk = debounce(
  (accountKey: string, setupListeners: () => void) => {
    /**
     * if not initialized, getAccountStatus will return undefined
     *
     * zendesk will log errors if trying to initialize more than once
     */
    if (!window.zChat?.getAccountStatus()) {
      const initOptions: ZopimInitOptions = {
        account_key: accountKey,
        suppress_console_error: false,
      };

      // pass along authenticate from parent page
      if (window.zESettings?.webWidget?.authenticate?.chat?.jwtFn) {
        initOptions.authentication = {
          jwt_fn: window.zESettings.webWidget.authenticate.chat.jwtFn,
        };
      }

      window.zChat?.init(initOptions);
    }
    setupListeners();
  },
  500,
);

/**
 * Needed to prevent potential duplicate calls.
 */
let timeout: NodeJS.Timeout;

/**
 * setTimeout is being used because these zopim APIs are
 * asynchronous but they do not return promises so we have
 * to arbitrarily wait for them to finish
 */
export const initZopimChat = async ({
  conversationId,
  conversationIdUsedInPreviousSession,
  integrationFields,
  liveChatWidgetComponent,
  targetOrigin = '*',
}: {
  conversationId: string;
  conversationIdUsedInPreviousSession: string;
  integrationFields: ZendeskLiveChatIntegrationConfig['integration_fields'];
  liveChatWidgetComponent: ForethoughtLiveChatHandoffComponent;
  targetOrigin?: string;
}) => {
  if (!liveChatWidgetComponent?.isSent) {
    const departmentId = (() => {
      const deptId = integrationFields.department_with_id?.id;
      const deptName = integrationFields.department_with_id?.name;

      if (deptId && !isNaN(parseInt(deptId))) {
        return parseInt(deptId);
      }

      // this covers a weird testing scenario where I am using
      // a context variable as the department input on begin zendesk chat action
      if (deptName && !isNaN(parseInt(deptName))) {
        return parseInt(deptName);
      }
    })();
    const tags = [
      ...(integrationFields.chat_tags || []),
      `ft-conversation-id_${conversationId}`,
    ];
    const shouldDoAvailabilityCheck = () => {
      if (
        liveChatWidgetComponent?.component_fields.integration_config
          .integration === 'zendesk' &&
        liveChatWidgetComponent?.component_fields.integration_config
          .integration_fields?.ignore_agent_availability
      ) {
        return false;
      }

      return true;
    };
    const postZopimMessage = (data: Record<string, unknown>) => {
      const ifr = document.getElementById(
        FORETHOUGHT_IFRAME_ID,
      ) as HTMLIFrameElement;
      if (ifr) {
        ifr.contentWindow?.postMessage(data, targetOrigin);
      } else {
        window.postMessage(data);
      }
    };

    if (timeout) {
      clearTimeout(timeout);
    }

    /**
     * setTimeout here gives enough time for debouncedSetupZopimSdk to run
     */
    timeout = setTimeout(async () => {
      // availability check
      if (departmentId && shouldDoAvailabilityCheck()) {
        const department = window.zChat?.getDepartment(departmentId);

        if (!department || department.status === 'offline') {
          const failureEvent: ForethoughtZopimFailureEvent['data'] = {
            error: !department
              ? `Department ${departmentId} is undefined`
              : `Department ${departmentId} is offline`,
            event: 'forethoughtZopimFailure',
          };
          postZopimMessage(failureEvent);
          return;
        }
      }
      // set liveChatStatus to 'pending'
      const pendingEvent: ForethoughtZopimPendingEvent['data'] = {
        event: 'forethoughtZopimPending',
      };
      postZopimMessage(pendingEvent);
      // end existing chat
      await applyZopimFunctionWithCallback('endChat', {
        clear_dept_id_on_chat_ended: true,
      });
      // handle department
      if (departmentId) {
        // clear existing visitor default department
        await applyZopimFunctionWithCallback('clearVisitorDefaultDepartment');
        // set visitor default department
        await applyZopimFunctionWithCallback(
          'setVisitorDefaultDepartment',
          departmentId,
        );
      }
      // remove previous conversation tag
      await applyZopimFunctionWithCallback('removeTags', [
        `ft-conversation-id_${conversationIdUsedInPreviousSession}`,
      ]);
      // add tags
      await applyZopimFunctionWithCallback('addTags', tags);
      // set visitor info
      await applyZopimFunctionWithCallback('setVisitorInfo', {
        display_name: integrationFields.visitor_name || '',
        email: integrationFields.visitor_email || '',
      });
      // send message
      await applyZopimFunctionWithCallback(
        'sendChatMsg',
        liveChatWidgetComponent?.component_fields.customer_facing_transcript ||
          '',
      );
      // tracking event
      const trackingEventData: ForethoughtSendTrackingEvent['data'] = {
        event: 'forethoughtSendTrackingEvent',
        trackingEvent: {
          conversation_id: conversationId,
          department_id: departmentId,
          display_name: integrationFields.visitor_name,
          email: integrationFields.visitor_email,
          event_type: 'widget-customer-handoff',
          integration: 'zendesk-zopim-live-chat',
          success: true,
          tags: tags,
        },
      };
      postZopimMessage(trackingEventData);
    }, 5000);
  }
};

/**
 * Used to initialize zopim web sdk on the parent page in order
 * for zopim persistence to work when we are in an iframe.
 */
export const setUpZopimOneChatListener = () => {
  const ifr = document.getElementById(
    FORETHOUGHT_IFRAME_ID,
  ) as HTMLIFrameElement;
  const targetOrigin = '*';

  // handlers
  const agentUpdateHandler = (data: ZopimEventData<'agent_update'>) => {
    ifr.contentWindow?.postMessage(
      {
        event: 'forethoughtZopimAgentUpdate',
        zopimData: data,
      },
      targetOrigin,
    );
  };
  const chatHandler = (data: ZopimEventData<'chat'>) => {
    ifr.contentWindow?.postMessage(
      {
        event: 'forethoughtZopimChat',
        zopimAgents: window.zChat?.getServingAgentsInfo() || [],
        zopimData: data,
      },
      targetOrigin,
    );
  };
  const connectionUpdateHandler = (
    data: ZopimEventData<'connection_update'>,
  ) => {
    ifr.contentWindow?.postMessage(
      {
        event: 'forethoughtZopimConnectionUpdate',
        zopimData: data,
      },
      targetOrigin,
    );
  };

  // listeners
  const setupListeners = () => {
    window.zChat?.on('agent_update', agentUpdateHandler);
    window.zChat?.on('chat', chatHandler);
    window.zChat?.on('connection_update', connectionUpdateHandler);
  };
  const removeListeners = () => {
    window.zChat?.un('agent_update', agentUpdateHandler);
    window.zChat?.un('chat', chatHandler);
    window.zChat?.un('connection_update', connectionUpdateHandler);
  };

  // message listener to listen from iframe
  window.addEventListener('message', (e: ForethoughtEvent) => {
    switch (e.data.event) {
      case 'forethoughtZopimInit':
        const {
          conversationId,
          conversationIdUsedInPreviousSession,
          integrationFields,
          liveChatWidgetComponent,
        } = e.data;
        if (window.zChat) {
          debouncedSetupZopimSdk(integrationFields.account_key, setupListeners);
          initZopimChat({
            conversationId,
            conversationIdUsedInPreviousSession,
            integrationFields,
            liveChatWidgetComponent,
            targetOrigin,
          });
        } else {
          const script = document.createElement('script');
          script.id = ZOPIM_SCRIPT_ID;
          script.src = ZENDESK_WEB_SDK;
          script.fetchPriority = 'high';
          script.onload = () => {
            debouncedSetupZopimSdk(
              integrationFields.account_key,
              setupListeners,
            );
            initZopimChat({
              conversationId,
              conversationIdUsedInPreviousSession,
              integrationFields,
              liveChatWidgetComponent,
              targetOrigin,
            });
          };

          const nonce = document.currentScript?.getAttribute('nonce');
          if (nonce) {
            script.nonce = nonce;
          }

          document.body.appendChild(script);
        }
        break;
      case 'forethoughtZopimRemove':
        removeListeners();
        document.getElementById(ZOPIM_SCRIPT_ID)?.remove();
        break;
      case 'forethoughtZopimApplyFunction':
        if (window.zChat) {
          // reconstruct file on parent page
          if (e.data.functionName === 'sendFile') {
            try {
              const file: File = e.data.args[0];
              // get file content, we are reading it as a DataURL
              const fileContent: string = e.data.args[1].split(',')[1];
              e.data.args[0] = recreateFile(file, fileContent);
              // remove file content from args before sending to zopim
              window.zChat[e.data.functionName](
                ...[e.data.args[0], ...e.data.args.slice(2)],
              );
            } catch (e) {
              // do nothing
              break;
            }
          } else {
            // @ts-expect-error TS2556
            window.zChat[e.data.functionName](...e.data.args);
          }
        }
        break;
    }
  });
};

/**
 * Call a function on window.zChat or postMessage so parent page
 * handles the function call.
 *
 * Example - `applyZopimFunction('sendChatMsg', 'hello');`
 */
export const applyZopimFunction = <K extends keyof ZopimSdk>(
  fn: K,
  ...args: ZopimFunctionArgsHelper<K>
) => {
  if (window.zChat) {
    // @ts-expect-error TS2556
    window.zChat[fn](...args);
  } else {
    // handle sending file from iframe to parent page
    if (fn === 'sendFile') {
      const reader = new FileReader();
      reader.onload = () => {
        const content = reader.result;
        postMessage({
          args: [...args, content],
          event: 'forethoughtZopimApplyFunction',
          functionName: fn,
        });
      };
      reader.readAsDataURL(args[0] as File);
    } else {
      postMessage({
        args: [...args],
        event: 'forethoughtZopimApplyFunction',
        functionName: fn,
      });
    }
  }
};

/**
 * Recreate a file at the parent page from iframe postMessage
 */
const recreateFile = (file: File, content: string): File => {
  const byteCharacters = atob(content);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: file.type });
  return new File([blob], file.name, { type: file.type });
};

/**
 * Get the origin of the parent window.
 *
 * First try `window.location.ancestorOrigins`, then `document.referrer`.
 * if neither of those are defined or parse to a valid url, return an empty string.
 *
 */
export default function getParentOrigin() {
  try {
    // eslint-disable-next-line compat/compat -- we use `?.`
    const firstAncestorOrigin = window.location.ancestorOrigins?.[0];
    if (firstAncestorOrigin) {
      return new URL(firstAncestorOrigin).origin;
    }

    if (document.referrer) {
      return new URL(document.referrer).origin;
    }

    return '';
  } catch {
    return '';
  }
}

export const wait = (ms: number) => {
  return new Promise(res => setTimeout(res, ms));
};

export async function callWithRetry<T>(
  fn: () => T,
  depth = 0,
): Promise<T | null> {
  const ret = fn();
  if (ret) return ret;
  else {
    if (depth > 4) return null;
    await wait(2 ** depth * 600);
    return callWithRetry(fn, depth + 1);
  }
}

export const widthCondition = window.matchMedia('(max-width: 480px)');
export const getDynamicWidthCondition = () => {
  const doc = window.document;
  const currentScript = doc.currentScript;

  if (!currentScript) throw new Error('FT: Could not find embed script');

  const offsetX = currentScript.getAttribute('offset-x') || '0px';
  const margin = offsetX === '0px' ? '6px' : '30px';

  return window.matchMedia(
    `(min-width: 481px) and (max-width: calc(688px + ${offsetX} - ${margin}))`,
  );
};
export const smallHeightCondition = window.matchMedia('(max-height: 700px)');

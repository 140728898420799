/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * https://www.joshwcomeau.com/snippets/javascript/debounce
 *
 * @param callback function that takes any number of arguments
 * @param wait time in ms before calling the callback
 * @returns void
 */
export const debounce = (callback: (...args: any[]) => void, wait = 250) => {
  let timeoutId: number | undefined;

  return (...args: any[]) => {
    window.clearTimeout(timeoutId);

    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

import { Placement, Size } from 'api/types';

const state: {
  closedWidgetDimensions: { height: string; width: string };
  isOpen: boolean;
  isViewingImage: boolean;
  placement: Placement;
  size: Size;
} = {
  closedWidgetDimensions: {
    height: '0',
    width: '0',
  },
  isOpen: false,
  isViewingImage: false,
  placement: 'bottom_right',
  size: 'standard',
};

export default state;
